export default {
  'assessmentContent.addSection': 'Bölüm Ekle',
  'assessmentContent.editSection': 'Bölümü Düzenle',
  'assessmentContent.addQuestion': 'Soru Ekle',
  'assessmentContent.reset': 'Sıfırla',
  'assessmentContent.editDesc': 'Açıklamayı Düzenle',
  'assessmentContent.assessmentDescPrev': 'Değerlendirme Açıklaması Önizleme',
  'assessmentContent.sectionDescPrev': 'Bölüm Açıklaması Önizleme',
  'assessmentContent.sectionDesc': 'Bölüm Açıklaması',
  'assessmentContent.assessmentDesc': 'Değerlendirme Açıklaması',
  'assessmentContent.deleteSection': 'Bölümü sorularla birlikte sil',
  'assessmentContent.moveQuestions': 'Bölümü sil ve soruları taşı',
  'assessmentContent.type': 'Tür: {type}',
  'assessmentContent.category': 'Kategori: {category}',
  'assessmentContent.unit': 'Birim: {count}',
  'assessmentContent.course': 'Ders: {count}',
  'assessmentContent.topic': 'Konu: {count}',
  'assessmentContent.timeLimit': 'Toplam Süre Sınırı: {time} {unit}',
  'assessmentContent.totalQuestion': 'Toplam Soru: {number}',
  'assessmentContent.totalCriteria': 'Toplam Ölçüt: {number}',
  'assessmentContent.totalPoint': 'Toplam Puan: {number}',
  'assessmentContent.points': 'puan',
  'assessmentContent.shuffled': 'Karıştır',
  'assessmentContent.sectionTime': 'Bölüm Süre Sınırı:',
  'assessmentContent.sectionLimit': 'Bölüm Süre Sınırı: {time} dakika',
  'assessmentContent.sectionName': 'Bölüm Adı',
  'assessmentContent.minutes': ' (Dakika)',
  'assessmentContent.shuffle': 'Bu bölümdeki soruları karıştır',
  'assessmentContent.newQuestion': 'Yeni Soru Ekle',
  'assessmentContent.bank': 'Soru Bankasından Seç',
  'assessmentContent.edit': '{name} Düzenle',
  'assessmentContent.delete': '{name} Sil',
  'assessmentContent.add': 'Seçilen {param} Soruyu Ekle',
  'assessmentContent.total': 'Seçilmemiş',
  'assessmentContent.added': 'Seçilmiş',
  'assessmentContent.popupTitle': 'Soru Bankasından Soru Seçiniz',
  'assessmentContent.section': 'Bölüm {index}: ',
  'assessmentContent.timeWarning': 'Bu alan yalnızca sayı içermelidir',
  'assessmentContent.required': 'Bu alan boş bırakılamaz',
  'assessmentContent.limitExceeded':
    'Bölümlerin süre limitlerinin toplamı değerlendirmenin süre limitini aşmamalıdır.',
  'assessmentContent.next': 'Sonraki Adım',
  'assessmentContent.prev': 'Önceki Adım',
  'assessmentContent.questionWarning':
    'Değerlendirmeye eklenmiş soru bulunmamaktadır',
  'assessmentContent.gradingWarning': 'Lütfen zorunlu alanları doldurunuz!',
  'assessmentContent.sectionWarning':
    '{section} bölümüne eklenmiş soru bulunmamaktadır',
  'assessmentContent.list': 'Liste Görünümü',
  'assessmentContent.blueprint': 'Belirtke Tablosu Görünümü',
  'assessmentContent.nextStepAlert':
    'Diğer adıma geçmek için liste görünümünü kullanın.',
  'assessmentContent.prevStepAlert':
    'Önceki adıma geçmek için liste görünümünü kullanın.',
  'assessmentContent.resetConfirmationTitle': 'Belirtke Tablosunu Sıfırla',
  'assessmentContent.resetConfirmationMessage':
    'Tüm belirtke tablosunu sıfırlayacaksınız. Bu işlemi yapsanız bile liste görünümünden devam edebilirsiniz. Onaylıyor musunuz?',
  'assessmentContent.resetConfirmationConfirmButton': 'Evet, tabloyu sıfırla',
  'assessmentContent.resetConfirmationCancelButton': 'İptal',
  'assessmentContent.passPointWarning':
    'Geçme puanı 0 ile en yüksek puan arasında olmalıdır!',
  'assessmentContent.cancel': 'Çıkış',
  'assessmentContent.noDesc': 'Açıklama yok.',
  'assessmentContent.rubricWarning':
    'Lütfen dereceli puanlama anahtarı seçiniz!',
  'assessmentContent.maxMessageSectionName':
    'Bölüm adı en fazla 100 karakter olmalıdır.',
  'assessmentContent.maxMessageSectionDesc':
    'Bölüm açıklaması en fazla 5000 karakter olmalıdır.',
  'assessmentContent.continueTitle': 'Devam etmek istediğinize emin misiniz?',
  'assessmentContent.addGroupedConfirm':
    'Değerlendirmeye eklemek istediğiniz soru(lar) arasında gruplandırılmış bir soru bulunmaktadır. Devam ederseniz, gruplandırılmış soru içeriğindeki diğer sorular da eklenecektir.',
  'assessmentContent.continue': 'Devam',
  'assessmentContent.cancelPopup': 'İptal',
  'assessmentContent.questionPointWarning':
    'Bir soru 0 puan olamaz. Lütfen tüm soruların geçerli bir puanı olduğundan emin olun.',
};
